import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import BreastfeedingImg1 from '@/assets/img/advocacy-for-children/breastfeeding_img1@2x.png';
import BreastfeedingImg2 from '@/assets/img/advocacy-for-children/breastfeeding_img2@2x.png';
import InfoObj1 from '@/assets/img/advocacy-for-children/breastfeeding_info1@2x.png';
import InfoObj2 from '@/assets/img/advocacy-for-children/breastfeeding_info2@2x.png';
import InfoObj3 from '@/assets/img/advocacy-for-children/breastfeeding_info3@2x.png';
import ButtonMoreView from '@/components/ButtonMoreView';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import {
  NoList,
  SectionObj,
  ObjList,
  CardFlex,
} from '@/components/Css/PublicPrivatePartnership';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import Section, { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import Youtube from '@/components/Youtube';
import YoutubeSingle from '@/components/YoutubeSingle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PublicPrivateTab } from '@/page-blocks/advocacy-for-children/public-private-partnership';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const infoData = [
  {
    id: 1,
    img: InfoObj3,
    tit: `정보 제공`,
    link: `#info`,
    dec: `정확하고 객관적인 정보를 토대로 모유수유를 장려합니다.`,
  },
];

const youtubeData2: TabData[] = [
  {
    id: 0,
    youtubeId: `mOVJAMD2yKk`,
    title: `1화: 아기를 위한 완전식품, 엄마 젖`,
  },
  {
    id: 1,
    youtubeId: `vZL9RuCAKfk`,
    title: `2화: 젖 먹이는 엄마가 더 건강해요`,
  },
  {
    id: 2,
    youtubeId: `rCVsaRgZX1c`,
    title: `3화: 유방 통증! 이렇게 극복하세요`,
  },
  {
    id: 3,
    youtubeId: `GlZ-AWAvTrA`,
    title: `4화: 젖 먹이기! 시작이 중요해요`,
  },
  {
    id: 4,
    youtubeId: `ULFY45GdA38`,
    title: `5화: 젖 먹이는 자세`,
  },
  {
    id: 5,
    youtubeId: `Ij6WWEyDxec`,
    title: `6화: 젖 먹기를 거부하는 아기`,
  },
  {
    id: 6,
    youtubeId: `VK-lzPg6AVM`,
    title: `7화: 아기는 충분한 젖을 먹고 있을까요?`,
  },
  {
    id: 7,
    youtubeId: `_JZxPTvc_DU`,
    title: `8화: 직장 여성의 젖 먹이기 성공 비법`,
  },
  {
    id: 8,
    youtubeId: `2Va7iM7Yuv4`,
    title: `9화: 일터에서 가저온 영양만점 엄마 젖`,
  },
  {
    id: 9,
    youtubeId: `_m5KEek95Ow`,
    title: `10화: 젖 먹이기! 이것이 궁금해요`,
  },
];

const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `아동권리본부`,
    tel: ``,
    email: `crc@unicef.or.kr`,
  },
];

const CertifyWrap = styled.div`
  margin-top: 64px;
  dl {
    padding-bottom: 64px;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      border-right: 1px dashed #1cabe2;
      position: absolute;
      top: 0;
      left: 30px;
      z-index: -1;
    }
    &:last-child {
      padding-bottom: 0;

      &:before {
        display: none;
      }
    }

    .tit {
      display: inline-block;
      line-height: 48px;
      border-radius: 48px;
      padding: 0 24px;
      background: #3162af;
      &.t2 {
        background: #2589c9;
      }
      &.t3 {
        background: #1cabe2;
      }
      &.t4 {
        background: #72c9eb;
      }
      &.t5 {
        background: #e2f0f6;
      }
    }
    dd {
      padding-top: 16px;
      padding-left: 64px;

      .blit-list {
        line-height: 2;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    margin-top: 48px;
    dl {
      padding-bottom: 48px;

      &:before {
        left: 23px;
      }

      .tit {
        line-height: 40px;
        border-radius: 40px;
      }
      dd {
        padding-left: 48px;
      }
    }
  }
`;
const Tbl = styled.table`
  margin-top: 64px;
  tbody {
    th {
      height: auto;
      text-align: center;
      font-weight: 400;
      background: #f8f9fa;
    }
    tr:last-child {
      th,
      td {
        border-bottom-color: #000;
      }
    }
    td {
      padding: 0;
      height: auto;
      ul {
        & > li {
          width: 100%;
          line-height: 2;
          border-bottom: 1px solid #ddd;
          padding: 14px 0;

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
`;
const SectionInfo = styled(Section)`
  ${ObjList} {
    margin-top: 64px;
    & > li {
      width: 100%;
      margin-bottom: 32px;
      display: flex;
      text-align: left;

      &:last-child {
        margin-bottom: 0;
      }

      .link-item {
        color: #1cabe2;
        font-size: 14px;
        margin-left: 8px;
        &:hover {
          text-decoration: underline;
        }
      }
      .img-wrap {
        width: 56px;
      }
      .dec-wrap {
        width: calc(100% - 56px);
        padding-left: 24px;
        margin-top: 0;

        .tit {
          display: inline-block;
        }
        .dl-wrap {
          margin-top: 8px;

          dl {
            text-align: left;
            margin-bottom: 16px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .dec {
          margin-top: 8px;
        }
      }
    }
  }
  ${breakpoint(`tablet`)} {
    ${ObjList} {
      margin-top: 48px;
      & > li {
        margin-bottom: 24px;
        flex-wrap: wrap;

        .img-wrap {
          text-align: left;
          width: 100%;
          display: flex;
          align-items: center;

          ${Image} {
            width: 56px;
            margin-right: 8px;
          }
        }
        .dec-wrap {
          width: 100%;
          padding: 0;
          margin-top: 8px;

          .dl-wrap {
            dl {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
`;
const SectionHospital = styled(SectionObj)`
  ${CardFlex} {
    & > li {
      width: 100%;
      margin-top: 88px;

      .card {
        ${Tit} {
          border-radius: 48px;
          left: 32px;
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    ${CardFlex} {
      & > li {
        margin-top: 70px;

        .card {
          padding-top: 60px;
          padding-bottom: 50px;
          ${Tit} {
            left: 16px;
          }
        }
      }
    }
  }
`;
const SectionWorkplace = styled(SectionObj)`
  .articel.a1 {
    margin-bottom: 240px;
    .blit-list {
      margin-top: 32px;
      line-height: 2;
    }
  }
  ${CardFlex} {
    margin-top: 64px;

    & > li {
      width: 100%;
    }
    ${NoList} {
      & > li {
        width: 50%;

        &:nth-child(2) {
          order: 3;
        }
        &:nth-child(3) {
          order: 5;
        }
        &:nth-child(4) {
          order: 7;
        }
        &:nth-child(5) {
          order: 9;
        }
        &:nth-child(6) {
          order: 2;
        }
        &:nth-child(7) {
          order: 4;
        }
        &:nth-child(8) {
          order: 5;
        }
        &:nth-child(9) {
          order: 8;
        }
        &:nth-child(10) {
          order: 10;
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    .articel.a1 {
      margin-bottom: 48px;
    }
    ${CardFlex} {
      margin-top: 48px;

      .card {
        padding-top: 50px;
        padding-bottom: 50px;
      }
      & > li {
        width: 100%;
      }
      ${NoList} {
        & > li {
          width: 100%;

          &:nth-child(2) {
            order: 2;
          }
          &:nth-child(3) {
            order: 3;
          }
          &:nth-child(4) {
            order: 4;
          }
          &:nth-child(5) {
            order: 5;
          }
          &:nth-child(6) {
            order: 6;
          }
          &:nth-child(7) {
            order: 7;
          }
          &:nth-child(8) {
            order: 8;
          }
          &:nth-child(9) {
            order: 9;
          }
        }
      }
    }
  }
`;
const SectionBreastFeeding = styled(Section)`
  ${SectionHeader} {
    padding-bottom: 64px;
  }
  .dl-container {
    dl {
      margin-bottom: 96px;

      &:last-child {
        margin-bottom: 0;
      }
      dd {
        margin-top: 32px;

        .dec {
          margin-bottom: 32px;

          &:last-child {
            margin-bottom: 0;
          }
        }
        .blit-list {
          margin-top: 64px;
          line-height: 2;

          & > li {
            margin-bottom: 8px;
            &:last-child {
              margin-bottom: 0;
            }
          }
          strong {
            display: block;
            color: #1cabe2;
          }
        }
      }
    }
  }
  ${breakpoint(`tablet`)} {
    ${SectionHeader} {
      padding-bottom: 48px;
    }
    .dl-container {
      dl {
        margin-bottom: 48px;

        dd {
          margin-top: 24px;

          .dec {
            margin-bottom: 24px;
          }
          .blit-list {
            margin-top: 48px;
          }
        }
      }
    }
  }
`;
const SectionYoutube = styled(Section)`
  .btn-wrap {
    margin-top: 64px;
    text-align: right;

    ${ButtonMoreView} {
      text-align: left;
      width: 100%;
      max-width: 352px;
    }
  }
  ${breakpoint(`tablet`)} {
    .btn-wrap {
      margin-top: 48px;

      ${ButtonMoreView} {
        max-width: 100%;
      }
    }
  }
`;

const CardFlexBox = styled(CardFlex)`
  & > li {
    .card {
      ${Tit} {
        position: static;
      }
      ${NoList} {
        margin: 64px 0;
        :last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

const Breastfeeding: React.FC<PageProps> = ({ location }) => {
  const [news, setNews] = useState<CampaignData[]>([]);

  const loadNews = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A027'],
      });
      const articles = data as any;
      setNews(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadNews();
  }, [loadNews]);

  return (
    <LayoutWithTitle
      // 강제로 breadcrumb 고정
      location={{
        ...location,
        pathname: `/what-we-do/advocacy-for-children/public-private-partnership/child-friendly-cities`,
      }}
      paddingBtt
      title="아동친화사회 만들기"
      description="for every child, breastfeeding"
    >
      <PublicPrivateTab currentPath={location.pathname} />
      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  모든 사회 구성원이 함께 하는 <br />
                  모유수유 캠페인
                </PageComponent>
              </Tit>
            </h2>
            <p className="header-dec">
              <PageComponent id="content1">
                유니세프와 세계보건기구는 생후 1시간 내에 첫 모유수유를
                시작하여, 6개월 동안은 모유수유만, 최소 만 2세까지는 다른 음식과
                함께 모유수유를 지속할 것을 권고합니다. 첫 6개월 간 아기에게
                모유만 먹이면, 아기의 감각기관과 인지 발달을 촉진시키고,
                전염성이거나 만성인 질환으로부터 아기를 보호할 가능성이
                높아집니다. 모유수유를 함으로써 엄마도 유방암, 난소암, 심장질환
                등의 질병을 예방할 수 있습니다. 게다가 모유수유는 아기와 양육자
                간의 건강한 관계 형성에 큰 도움이 되며, 사회 전반적으로 건강한
                육아가 무엇인지 고민할 수 있는 기회를 제공합니다.
                <br />
                <br />
                모유수유는 여성 개인의 의지와 역량만의 문제가 아닙니다.
                여성에게는 모유수유에 대한 정확한 정보를 바탕으로 모유수유의
                장점, 이를 하지 않았을 경우 얻게 되는 결과 등을 이해한 후,
                모유수유에 대한 자기결정을 할 권리가 있습니다. 덧붙여, 성공적인
                모유수유를 위해서는 우리 모두가 건강한 육아를 하는 환경과 문화를
                조성하는데 동참해야 합니다.
              </PageComponent>
            </p>
          </SectionHeader>
          <PageComponent id="youtube1">
            <YoutubeSingle videoId="mOVJAMD2yKk" />
          </PageComponent>
        </Container>
      </Section>

      <SectionHospital className="by-sub-main-layout" id="hospital">
        <Container>
          <div className="flex-wrap flex">
            <SectionHeader className="with-desc">
              <h2>
                <Tit size="s1">병원에서의 모유수유</Tit>
              </h2>
              <p className="header-dec">
                대부분의 산모는 의료기관에서 아기를 출산하고 모유수유에 대한
                상담과 정보를 받게 됩니다. 출산 후 3~7일간의 모유수유 경험이
                이후 모유수유 성패를 좌우할 가능성이 높습니다. 이러한 이유에서
                출산 후 1시간 이내에 엄마와 아기가 피부를 맞대고 모유수유를 할
                수 있게 하거나, 이 둘이 같은 공간에서 지낼 수 있게 하는 등
                모유수유에 대한 의료기관 정책 마련 및 이행이 매우 중요합니다.
              </p>
            </SectionHeader>
            <figure>
              <Image pcSrc={BreastfeedingImg1} mobileSrc={BreastfeedingImg1} />
            </figure>
          </div>

          <div className="article-container">
            <article>
              <h3>
                <Tit size="s2">병원에서의 모유수유 지원을 위한 10가지 정책</Tit>
              </h3>
              <CardFlexBox>
                <li>
                  <div className="card">
                    <Tit size="s4" color="white">
                      주요 관리 절차 및 정책
                    </Tit>
                    <NoList>
                      <li>
                        <ul className="blit-list black">
                          <li>
                            모유대체품 판촉에 대한 국제규약과 관련 세계보건총회
                            결의안을 온전하게 준수합니다.
                          </li>
                          <li>
                            직원과 부모들이 일상적으로 이용할 수 있는 모유수유
                            정책을 문서화 합니다.
                          </li>
                          <li>
                            지속적인 모니터링과 데이터 관리 체계를 구축합니다.
                          </li>
                        </ul>
                      </li>
                      <li>
                        직원들이 모유수유를 지원하고 지지할 수 있는 충분한 지식,
                        역량 및 기술을 갖추도록 합니다.
                      </li>
                    </NoList>
                    <Tit size="s4" color="white">
                      주요 임상 관행
                    </Tit>
                    <NoList>
                      <li />
                      <li />
                      <li>
                        임산부 및 임산부의 가족들과 함께 모유수유의 중요성과
                        관리에 대해 논의합니다.
                      </li>
                      <li>
                        출산 후 최대한 즉각적이고 지속적인 엄마-아기 간의
                        피부접촉을 가능하게 하고, 출산 후의 산모가 최대한
                        신속하게 모유수유를 시작할 수 있도록 지원합니다.
                      </li>
                      <li>
                        산모가 모유수유를 시작하고 유지하는 과정에서 흔히
                        경험하는 어려움을 극복하도록 지원합니다.
                      </li>
                      <li>
                        의학적으로 적응되지 않을 시, 모유수유 중인 아기에게 모유
                        외의 음식 및 음료를 먹이지 않습니다.
                      </li>
                      <li>
                        산모와 아기가 하루 24시간 동안 같은 방에 지내면서
                        모자동실을 실천할 수 있도록 합니다.
                      </li>
                      <li>
                        아기가 모유수유를 원하여 보내는 신호를 산모가 인지하고
                        이에 반응할 수 있도록 돕습니다.
                      </li>
                      <li>
                        엄마에게 분유병, 분유병 젖꼭지, 노리개 젖꼭지의 사용법과
                        주의사항에 대해 안내합니다.
                      </li>
                      <li>
                        퇴원 후에도 부모와 아기가 지속적인 지원과 보살핌을 제때
                        받을 수 있도록 퇴원 시 안내합니다.
                      </li>
                    </NoList>
                  </div>
                </li>
              </CardFlexBox>
            </article>
          </div>
        </Container>
      </SectionHospital>

      <SectionWorkplace className="by-sub-main-layout" id="workplace">
        <Container>
          <div className="flex-wrap flex">
            <SectionHeader className="with-desc">
              <h2>
                <Tit size="s1">직장에서의 모유수유</Tit>
              </h2>
              <p className="header-dec">
                여성이 육아와 업무를 병행할 수 있도록, 기업은 모유수유를
                지원하는 ‘정책’, ‘시설’, ‘교육’ 및 ‘시간 확보’를 지원해야
                합니다. 또 ‘직장에서의 성공적인 모유수유 10단계’를 적극적으로
                이행함으로써, 직원의 일할 권리, 모유수유를 통한 양육자의 육아에
                대한 권리 그리고 아기의 생존 및 건강할 권리를 동시에 존중하고
                지원할 수 있습니다.
              </p>
            </SectionHeader>
            <figure>
              <Image pcSrc={BreastfeedingImg2} mobileSrc={BreastfeedingImg2} />
            </figure>
          </div>

          <div className="article-container">
            <article>
              <h3>
                <Tit size="s2">직장에서의 모유수유 지원을 위한 10가지 정책</Tit>
              </h3>
              <CardFlex>
                <li>
                  <div className="card">
                    <NoList className="flex">
                      <li>출산 후 최소 3개월 이상 출산휴가 보장</li>
                      <li>
                        모유수유 직원에게 근무 시간 조정, 업무 분담 등 탄력적인
                        근무 여건 제공
                      </li>
                      <li>하루 1시간 모유수유/착유를 위한 시간 제공</li>
                      <li>직장 내 또는 직장 근처에 보육 시설 지원</li>
                      <li>모유를 유축하고 보관할 수 있는 시설 제공</li>
                      <li>모유수유하는 여성을 존중하는 사내 문화 조성</li>
                      <li>깨끗하고 안전한 근무 환경 유지</li>
                      <li>여성 직원과 노조를 대상으로 출산휴가 등 정책 안내</li>
                      <li>육아 휴직 후, 직장 복귀 및 탄력 근무 지원</li>
                      <li>
                        모유수유와 업무를 병행하도록 돕는 직원 지원 네트워크
                        수립
                      </li>
                    </NoList>
                  </div>
                </li>
              </CardFlex>
            </article>
          </div>
        </Container>
      </SectionWorkplace>

      <SectionBreastFeeding className="by-sub-main-layout" id="info">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                모유수유, <br />
                객관적인 정보로 알아보세요!
              </Tit>
            </h2>
          </SectionHeader>
          <div className="dl-container">
            <dl>
              <dt>
                <Tit size="s2" color="sky">
                  “모유수유는 아동권리를 실현하는 필수 요소”
                </Tit>
              </dt>
              <dd>
                <p className="dec">
                  우리나라는 1991년 유엔아동권리협약에 비준하였습니다.
                  유엔아동권리협약 제24조에 따르면, 모든 당사국은 어린이가
                  최상의 건강수준을 유지할 권리와 질병치료 및 건강회복을 위한
                  시설을 이용할 권리를 인정해야 하며, 그 권리가 침해되지 않도록
                  노력해야 합니다. 해당 권리의 완전한 이행을 위해 취해야 하는
                  적절한 조치 중 하나가{` `}
                  <strong>
                    ‘부모와 어린이를 비롯한 모든 사회구성원이 어린이의 건강과
                    영양, 모유수유의 장점, 위생 및 환경정화, 사고 예방에 관한
                    정보를 제공받고 교육받을 수 있도록 하는 조치’
                  </strong>
                  {` `}
                  입니다.
                </p>
                <p className="dec">
                  모유수유는 부모와 어린이 모두의 인권과 깊은 관계가 있습니다.
                  모든 어린이는 생명, 존중, 발달에 대한 권리와 최상의 건강을
                  유지할 권리가 있습니다.{` `}
                  <strong>
                    모유수유는 안전하고 영양가 있는 식품일 뿐만 아니라 이와 같은
                    권리를 실현하는데 있어서 필수적인 구성요소입니다.
                  </strong>
                  {` `}
                  여성들은 모유수유에 대한 충분한 이해를 기반으로 모유수유에
                  대한 자기결정권을 가지며, 그 권리를 실현하기 위해 모유수유에
                  대한 정확하고 객관적인 정보를 제공받을 권리가 있습니다. 이와
                  함께 직장 내 모성보호 및 모유수유 친화적인 공간 제공은 여성의
                  권리를 존중하는 동시에 성공적인 모우수유 경험으로 이어집니다.
                </p>
              </dd>
            </dl>

            <dl>
              <dt>
                <Tit size="s2" color="sky">
                  “모유수유의 중요성과 긍정적 영향”
                </Tit>
              </dt>
              <dd>
                <p className="dec">
                  <strong>
                    유니세프와 WHO는 생후 첫 6개월동안 완전 모유수유 그리고 최소
                    만 2세까지는 다른 음식과 함께 모유수유를 지속할 것을
                    권고합니다. WHO 회원국들은 2025년까지 완전 모유수유 비율을
                    최소 50%까지 늘리는 것을 목표로 하고 있습니다.
                  </strong>
                </p>
                <ul className="blit-list">
                  <li>
                    <strong>영유아 건강</strong>
                    감염, 당뇨, 천식, 심장질환, 과체중 등의 질환으로부터 보호
                  </li>
                  <li>
                    <strong>모성 건강</strong>
                    유방암, 난소암, 심장질환 등의 질환으로부터 보호
                  </li>
                  <li>
                    <strong>관계 형성</strong>
                    엄마와 아기의 정신건강 보호 및 건강한 관계 형성
                  </li>
                  <li>
                    <strong>엄마와 아기 상호 혜택</strong>
                    전 세계적으로, 해당 국가가 저소득 또는 고소득 국가임에
                    관계없이, 엄마와 아기 모두 모유수유로부터 긍정적인 영향을
                    받는다는 조사결과 존재 (The Lancet, 2016)
                    <br />
                    - 매년 5살 미만의 어린이 82만 3,000명의 사망을 예방
                    <br />- 매년 산모 2만 명 유방암에 의한 사망을 예방
                  </li>
                  <li>
                    <strong>지능 발달</strong>
                    모유 영양이 어린이의 학습 능력을 향상시킴. 정상적인 뇌기능과
                    망막기능 유지에 필수적인 DHA 등의 필수요소가 분유에 함유되어
                    있지만, 모유에 비하여 그 양과 비율이 같지 않음
                  </li>
                  <li>
                    <strong>소득창출에 기여</strong>
                    12개월 이상 모유수유를 받은 어린이는 인지능력의 향상(대략 IQ
                    4점 향상)으로 성인이 되었을 때 보다 높은 소득을 창출한다는
                    조사결과 존재 (The Lancet, 2015)
                  </li>
                  <li>
                    <strong>비용 절감</strong>
                    모유수유를 하지 않음으로써 전 세계 경제는 매년 3,000억
                    달러가량의 비용을 감수 (The Lancet, 2016)
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
        </Container>
      </SectionBreastFeeding>

      <SectionYoutube className="by-sub-main-layout">
        <Container>
          <Youtube tabs={youtubeData2} />
          <div className="btn-wrap">
            <LinkSafe
              to="/what-we-do/database?category=KW09"
              className="link-item"
            >
              <ButtonMoreView mode="gray">
                모유수유 자료 더 보기{` `}
              </ButtonMoreView>
            </LinkSafe>
          </div>
        </Container>
      </SectionYoutube>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A027" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {news.length > 0 && (
            <RelativeBoardCard
              newslist={news}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>

      <Section className="by-sub-main-layout">
        <Container>
          <ContactUs itemData={contactData} />
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};
export default Breastfeeding;
